<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': theme === 'grey',
  }">
    <div class="d-flex">
      <div class="wrapper">
        <div class="price-input">
          <div class="field">
            <input name="somename"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   type = "number"
                   maxlength = "5"
                   class="input-min"
                   value="0"

            />
          </div>
          <div class="separator">-</div>
          <div class="field">
            <input name="somename"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   type = "number"
                   maxlength = "5"
                   class="input-max"
                   value="7500"
            />
          </div>
        </div>
        <div class="slider">
          <div class="progress"></div>
        </div>
        <div class="range-input">
          <input type="range" class="range-min"  min="0" max="10000" value="2500" step="100">
          <input type="range" class="range-max"  min="0" max="10000" value="7500" step="100">
        </div>
        <div class="min-max-prices row">
          <div class="min-price mr-auto col">{{ minprice }}лв</div>
          <div class="max-price col">{{ maxprice }}лв</div>
        </div>
      </div>
  </div>
  </div>
</template>

