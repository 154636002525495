
export default {
    name: 'Discount',
    props: {
        amount: {
            type: String,
            default: 'medium'
        },

    },
    components: {

    },
    mounted() {
    }
}